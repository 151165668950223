
import React, { useEffect, Component, ReactNode } from "react";
import { styled } from "@material-ui/core/styles";
import { Container, Paper, Typography, Accordion, AccordionSummary, AccordionDetails} from "@material-ui/core";
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface ErrorMessageProps {
  icon?: Component;
  message: String;
  stack?: String;
}

export const ErrorMessage = ({icon, message, stack}:ErrorMessageProps) => {

  return (
    <S.Wrapper component='div'>
      <S.Container>
        <span style={{fontSize: "40px", verticalAlign: "middle" }} >{ icon ? icon : <ReportProblemOutlinedIcon fontSize="inherit" color="error"/> }</span>
        <S.Message>{message}</S.Message>
        { (stack) && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                Details
              </AccordionSummary>
              <AccordionDetails>
                <pre>
                  {stack}
                </pre>
              </AccordionDetails>
            </Accordion>
        )}
      </S.Container>
    </S.Wrapper>
  )
};

const S = {
  Wrapper: styled(Container)({
    position: 'relative', 
    width: '100%',
    height: '100%', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
  }),
  Container: styled(Container)({
    textAlign: 'left',
    marginTop: '30px',
  }),
  Paper: styled(Paper)({
    padding: '20px',  
    marginTop: '20px', 
    textAlign: 'left',
    display: 'inline-block',
    
  }),
  Message: styled("span")({
    fontSize: "1rem",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: '1.5',
    letterSpacing: '0.00938em',
    marginLeft: '10px',
  })
}