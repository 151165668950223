import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import MUIDataTable, { MUIDataTableProps, TableFooter, TablePagination, ToolbarButton, MUIDataTableColumn, MUIDataTableState, FilterType } from "mui-datatables";
import { RootState } from '../state';
import { MuiTableStateProps, updateMUITableSearchTextState, updateMUITableState } from '../state/ldpUiTableStates';
import { FormControl, Grid, IconButton, Input, InputAdornment,} from '@material-ui/core';
import CustomFooter from './CustomFooter';
import { isRoleExternalAccountManager } from '../common/utils/roles';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

export interface TableColumnSort {
  name: string;
  direction: 'asc' | 'desc'
}

export interface TableState {
  sortOrder?: TableColumnSort;
  page?: number;
  rowsPerPage?: number;
  searchText?: string | null;
  filterList?: {
    [name: string]: any[];
  };
  columns?: {
    [name: string]: boolean;
  };
  search?: ToolbarButton;
}

export interface LDPTableProps extends MUIDataTableProps {
  dispatch?: Function;
  muiTableStates: MuiTableStateProps;
  ldpTableId: string;
  restoreFilters?: boolean;
  loggedInUser: RootState['loggedInUser'];
  searchAlwaysOpen?: boolean;
  searchPersistentKey?: string;
}

const LDPUIDataTable = ({ muiTableStates, dispatch, ldpTableId, columns, data, options, loggedInUser, title, searchAlwaysOpen, searchPersistentKey, ...rest }: LDPTableProps) => {
  // console.log("options", JSON.stringify(options));
  const muiTableState = (muiTableStates && muiTableStates[ldpTableId]) || {};
  const isUserExternalAccountManager = loggedInUser && isRoleExternalAccountManager(loggedInUser);  

  const [muiTableOptions, setMuiTableOptions] = useState<typeof options | null>(null);
  const [isMuiTableOptionsSet, setIsMuiTableOptionsSet] = useState<boolean>(false); //this needs to be true before setting options from Redux
  const [muiTableColumns, setMuiTableColumns] = useState<any[]>([]);
  const [lastColumnsConfig, setLastColumnsConfig] = useState<string>("");

  /* const [columnToggle, setColumnToggle] = useState<{
    [name: string]: boolean;
  }>(muiTableState?.columns || {});

  const [columnFilters, setColumnFilters] = useState<{
    [name: string]: any[];
  }>(muiTableState?.filterList || {});

  const [sortOrder, setSortOrder] = useState<TableColumnSort | undefined>(muiTableState?.sortOrder);
  const [page, setPage] = useState<number>(muiTableState?.page || 0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(muiTableState?.rowsPerPage || 10);
  */

  const [searchText, setSearchText] = useState<string | null | undefined>(null);

  useEffect(() => {
    if (searchPersistentKey) { 
      setSearchText(localStorage.getItem(searchPersistentKey) ?? muiTableState?.searchText);
    }
  }, [searchPersistentKey]);
  

  useEffect(() => {
    const currentColumnConfig = JSON.stringify(columns);

    if (muiTableColumns.length === 0 || lastColumnsConfig !== currentColumnConfig) {
      //console.log("LDPUIDataTable, updating column config");

      //process columns and set visibilities based on saved states
      const tableState: TableState = muiTableState || {};
      const { columns: columnsState } = tableState;
      const columnFilters = muiTableState?.filterList || {};

      columns.forEach(column => {
        if (columnsState && columnsState[column.name] !== undefined) {
          column.options.display = columnsState[column.name];
        }

        if (columnFilters[column.name] !== undefined) {
          column.options.filterList = columnFilters[column.name];
        }    
        
        if (isUserExternalAccountManager) {
          column.options.download = false;
        }
      });      

      setMuiTableColumns([...columns]);
      setLastColumnsConfig(currentColumnConfig);
    }
  }, [columns]);

  /* const getColumnStates = () => {
    return { ...columnToggle };
  }

  const getColumnFilters = () => {
    return { ...columnFilters };
  } */

  useEffect(() => {
    setMuiTableOptions({
      ...options,
      download: !isUserExternalAccountManager,
      print: !isUserExternalAccountManager,
      page: muiTableState?.page || 0,
      sortOrder: muiTableState?.sortOrder || options?.sortOrder || undefined,
      rowsPerPage: muiTableState?.rowsPerPage || options?.rowsPerPage || 10,
      searchText: muiTableState?.searchText || '',
      search: searchAlwaysOpen ? "disabled" : options?.search,
      searchOpen: searchAlwaysOpen ? false : options?.searchOpen,
      customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
        return (
          <CustomFooter
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={options?.rowsPerPageOptions || [10, 25, 50]}
            changeRowsPerPage={changeRowsPerPage}
            changePage={changePage}
            /* textLabels={textLabels} */
          />
        );
      },
      onTableChange: (action: string, tableState: MUIDataTableState) => {
        const { columns: columnList, filterList, rowsPerPage: currentRowsPerPage, sortOrder: updatedOrder, page: currentPage, searchText: keyword } = tableState;

        if (action !== "propsUpdate") {          
          
          const colStates: any = muiTableState?.columns || {};
          const newFilterList: any = muiTableState?.filterList || {};

          //pre-process the columns display state
          columnList.forEach((column: any) => {
            colStates[column.name] = column.display;
          });

          // setColumnToggle(colStates);

          columnList.forEach((column: any, index: number) => {
            newFilterList[column.name] = filterList[index];
          });

          //setColumnFilters(newFilterList);

          //setSortOrder({ ...updatedOrder });

          //setPage(currentPage);

          //setRowsPerPage(currentRowsPerPage);

          //setSearchText(keyword);

          columns.forEach(column => {
            column.options ||= {};
            column.options.display = colStates[column.name];
            column.options.filterList = newFilterList[column.name];
          });

          setMuiTableColumns([...columns]);

          dispatch && dispatch(updateMUITableState(ldpTableId, {
            columns: colStates, 
            filterList: newFilterList, 
            rowsPerPage: currentRowsPerPage, 
            sortOrder: updatedOrder, 
            page: currentPage,
            searchText: keyword,
            search: searchAlwaysOpen ? "disabled" : options?.search,
          }));
        }                     
      },
    });
  }, [options, isUserExternalAccountManager]);

  useEffect(()=>{
    if(muiTableOptions !== null && !isMuiTableOptionsSet){
      setIsMuiTableOptionsSet(true);
    }
  },[muiTableOptions, isMuiTableOptionsSet]);

  //update from redux
  useEffect(() => {
    if(!isMuiTableOptionsSet) return; //update oonly after initialOptions are set
    setMuiTableOptions({
      ...(muiTableOptions || {}),
      download: !isUserExternalAccountManager,
      print: !isUserExternalAccountManager,
      sortOrder: muiTableStates[ldpTableId]?.sortOrder,
      page: muiTableStates[ldpTableId]?.page || 0,
      rowsPerPage:  muiTableStates[ldpTableId]?.rowsPerPage || options?.rowsPerPage || 10,
      searchText:  muiTableStates[ldpTableId]?.searchText || '',
      search: searchAlwaysOpen ? "disabled" : options?.search,
      searchOpen: searchAlwaysOpen ? false : options?.searchOpen,
      customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
        return (
          <CustomFooter
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={options?.rowsPerPageOptions || [10, 25, 50]}
            changeRowsPerPage={changeRowsPerPage}
            changePage={changePage}
            /* textLabels={textLabels} */
          />
        );
      },
    });

    const { columns: columnsState } = muiTableStates[ldpTableId] || {};
    const columnFilters =  muiTableStates[ldpTableId]?.filterList || {};

    columns.forEach(column => {
      if (columnsState && columnsState[column.name] !== undefined) {
        column.options.display = columnsState[column.name];
      }

      if (columnFilters[column.name] !== undefined) {
        column.options.filterList = columnFilters[column.name];
      }
      
      if (isUserExternalAccountManager) {
        column.options.download = false;
      }
    });      

    setMuiTableColumns([...columns]);
  }, [ muiTableStates[ldpTableId], isMuiTableOptionsSet]);

  /* useEffect(() => {
    if (muiTableOptions) {      
      setMuiTableOptions({
        ...muiTableOptions,
        download: !isUserExternalAccountManager,
        print: !isUserExternalAccountManager,
        sortOrder,
        page,
        rowsPerPage,
        searchText: searchText || '',
        search: searchAlwaysOpen ? "disabled" : options?.search,
        searchOpen: searchAlwaysOpen ? false : options?.searchOpen,
      });
    }

  }, [sortOrder, page, rowsPerPage, searchText]); */

  /* if (data && data.length > 0 && muiTableOptions && (muiTableOptions?.page != undefined && muiTableOptions?.page > 0)) {          
    const totalPages = data.length / rowsPerPage;
    if (totalPages < muiTableOptions?.page) {
      setMuiTableOptions({
        ...muiTableOptions,
        download: !isUserExternalAccountManager,
        print: !isUserExternalAccountManager,
        sortOrder,
        page: 0,
        rowsPerPage,
        search: searchAlwaysOpen ? "disabled" : options?.search,
      searchOpen: searchAlwaysOpen ? false : options?.searchOpen,
      });
    }
  } */

  useEffect(() => {
    if (data && data.length > 0 && muiTableOptions && (muiTableOptions?.page != undefined && muiTableOptions?.page > 0)) {          
      const totalPages = data.length / (muiTableState?.rowsPerPage || options?.rowsPerPage || 10);
      if (totalPages < muiTableOptions?.page) {
        setMuiTableOptions({
          ...muiTableOptions,
          download: !isUserExternalAccountManager,
          print: !isUserExternalAccountManager,
          sortOrder: muiTableState?.sortOrder || options?.sortOrder || undefined,
          page: 0,
          rowsPerPage: muiTableState?.rowsPerPage || options?.rowsPerPage || 10,
          search: searchAlwaysOpen ? "disabled" : options?.search,
          searchOpen: searchAlwaysOpen ? false : options?.searchOpen,
        });

      }
    }
  }, [data]);

  useEffect(() => {
    if(searchText === '') {
      setSearchText(null);        
    }
  }, [searchText]);

  return (
    <>
      {isUserExternalAccountManager !== null && muiTableOptions && (
        
          <MUIDataTable
            title={
              searchAlwaysOpen ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                      <Input
                        id="mui-table-search-field"
                        type="text"
                        fullWidth
                        value={muiTableState?.searchText || ''}
                        onChange={
                          (evt) => {
                            setSearchText(evt.target.value);
                            if (searchPersistentKey) {
                              localStorage.setItem(searchPersistentKey, evt.target.value);
                              dispatch && dispatch(updateMUITableSearchTextState(ldpTableId, evt.target.value));
                            }
                          }
                        }
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="clear field"
                              title='Clear Search'
                              size='small'
                              color='secondary'
                              onClick={
                                (evt) => {
                                  setSearchText((prev) => {
                                    return '';
                                  });
                                  if (searchPersistentKey) {
                                    localStorage.setItem(searchPersistentKey, '');
                                    dispatch && dispatch(updateMUITableSearchTextState(ldpTableId, ''));
                                  }
                                }
                              }
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              ) : title
            }
            columns={muiTableColumns}          
            data={data}
            options={muiTableOptions || {}} {...rest} />
      )}
    </>
  )
}

export default connect((state: RootState) => ({
  muiTableStates: state.ldpUiTableStates,
  loggedInUser: state.loggedInUser,
}), null)(LDPUIDataTable);