import React, { ReactNode } from "react";
import { ApolloError } from "apollo-client";
import { PageLoading, ContentLoading } from "./loading";
import { styles } from "../styles";
import { styled } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { ErrorMessage } from "./errorMessage";
import { Container } from "@material-ui/core";
import { v4 as uuidv4 } from 'uuid';

const ERROR_CODES = {
  "VALIDATION_ERROR":"You are not authorized to view this page",
  "INVALID_VALUE":"Unable to fetch data. Contact technical support.",
  "TIMEOUT":"Connection timeout. Please try again later.",
  "UNKNOWN_ERROR":"Unknown error occured. Contact technical support.",
};

export const queryLoad = (
  loads: boolean[],
  errors: (ApolloError | undefined)[],
  data?: (any | undefined)[],
  fields?: string[],
  messages?: string[]
): ReactNode | undefined => 
{
  if (loads.some(l => l)) 
  {
    return <ContentLoading showTip={true}/>;
  } 
  else if(errors.some(e => !!e)) {
    return (
      <Container>
        {errors
          .filter(e => !!e)
          .map(e => {
            // toast.error(e && e.message);
            if (e?.graphQLErrors) {
              if(e.graphQLErrors.length){
                return (e.graphQLErrors.map(({ message, locations, path, extensions }) => {
                    if(extensions?.code)
                      return <ErrorMessage key={"error-message-"+ uuidv4()} message={ERROR_CODES[extensions.code]} stack={JSON.stringify(e, null, 2)}/>;
                    else
                      return <ErrorMessage key={"error-message-"+ uuidv4()} message={ERROR_CODES["UNKNOWN_ERROR"]} stack={JSON.stringify(e, null, 2)}/>;
                  }
                ))
              }
            }
            
            if (e?.networkError){
              if(e?.networkError?.result?.errors[0]?.extensions?.code)
                return <ErrorMessage key={"error-message-"+ uuidv4()} message={ERROR_CODES[e?.networkError.result.errors[0].extensions.code]} stack={JSON.stringify(e, null, 2)}/>;
              else
                return <ErrorMessage key={"error-message-"+ uuidv4()} message={ERROR_CODES["UNKNOWN_ERROR"]} stack={JSON.stringify(e, null, 2)}/>;
            } 
          })}
      </Container>
    );
  }
  // else if (errors.some(e => !!e)) 
  // {
  //   return (
  //     <S.ErrorList>
  //       {errors
  //         .filter(e => !!e)
  //         .map(e => {
  //           toast.error(e && e.message);
  //           return <pre>{JSON.stringify(e, null, 2)}</pre>;
  //         })}
  //     </S.ErrorList>
  //   );
  // } 
  // else if (data && fields && data.some((d, i) => !!(d && !d[fields[i]]))) 
  // {
  //   return (
  //     <S.ErrorList>
  //       {data
  //         .filter(d => !!d)
  //         .map(d => (
  //           <pre>{JSON.stringify(d, null, 2)}</pre>
  //         ))}
  //     </S.ErrorList>
  //   );
  // }
};
