import { GetAccountManagers_LDPUserConfigQueryGroup_UserConfig_UserConfigRole } from "../models/types/GetAccountManagers";

export const isRoleAdmin = (loggedInUser: any) : boolean => {
    const UserRoles = loggedInUser?.userData?.UserConfigRole;
    if (! UserRoles) return false;

    return UserRoles.some((UserRole: GetAccountManagers_LDPUserConfigQueryGroup_UserConfig_UserConfigRole) => UserRole.Role?.Name === "Administrator");        
}

export const isRolePriceAdjuster = (loggedInUser: any, isByPassWhenAdmin: boolean = false) : boolean => {        
    const UserRoles = loggedInUser?.userData?.UserConfigRole;
    if (! UserRoles) return false;        

    const isAdmin = isRoleAdmin(loggedInUser);

    // if is bypass and is admin deny this role
    if (isByPassWhenAdmin && isAdmin)
        return false;

    if (!isByPassWhenAdmin && isAdmin)
        return true;

    return UserRoles.some((UserRole: GetAccountManagers_LDPUserConfigQueryGroup_UserConfig_UserConfigRole) => UserRole.Role?.Name === "Price Adjuster");
}

export const isRoleExternalAccountManager = (loggedInUser: any, isByPassWhenAdmin: boolean = true) : boolean => {
    const UserRoles = loggedInUser?.userData?.UserConfigRole;
    if (! UserRoles) return false;  
    
    const isAdmin = isRoleAdmin(loggedInUser);

    // if is bypass and is admin deny this role
    if (isByPassWhenAdmin && isAdmin)
        return false;

    if (!isByPassWhenAdmin && isAdmin)
        return true;    
        
    return UserRoles.some((UserRole: GetAccountManagers_LDPUserConfigQueryGroup_UserConfig_UserConfigRole) => UserRole.Role?.Name === "External Account Manager");
}