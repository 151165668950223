import React from "react";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import MuiTablePagination from "@material-ui/core/TablePagination";
import { createStyles, makeStyles, Theme, useTheme, withStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';

const defaultFooterStyles = {};

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }),
);

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
  
export const CustomFooter = (props: any) => 
{
    const handleRowChange = (event) => {
        props.changeRowsPerPage(event.target.value);
    };

    const handlePageChange = (_, page) => {
        props.changePage(page);
    };

    const footerStyle = {
        display:'flex', 
        justifyContent: 'flex-end',
        padding: '0px 24px 0px 24px'
    }

    return (
        <TableFooter>
        <TableRow>
            <TableCell style={footerStyle} colSpan={1000}>          
            <MuiTablePagination
                component="div"
                count={props.count}
                rowsPerPage={props.rowsPerPage}
                page={props.page}
                /* labelRowsPerPage={props.textLabels.rowsPerPage}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${props.textLabels.displayRows} ${count}`} */
                rowsPerPageOptions={props?.rowsPerPageOptions}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowChange}
                ActionsComponent={TablePaginationActions}
            />
            </TableCell>
        </TableRow>
        </TableFooter>
    );
}

export default withStyles(defaultFooterStyles, { name: "CustomFooter" })(CustomFooter);