import React, { ReactNode, useState, useEffect, Fragment, useRef } from 'react';
import { styled, withStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { IconButton, Container, Box } from '@material-ui/core';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';

/*
 *
 *  Sample usage:
 *
 *     import TabsContainer, {TabsContainerProps} from "tabs";
 *
 *     const Demo = () => {
 *        const [sampleTabs, setSampleTabs] = useState<TabsContainerProps["tabs"]>([]);
 *        const removeTab = ( i:number ) => {
 *           setSampleTabs(sampleTabs.slice( 0, i).concat(sampleTabs.slice(i + 1, sampleTabs.length)));
 *        };
 *        return (
 *          <TabsContainer tabs={sampleTabs} onCloseTab={removeTab} />
 *        )
 *     }
 *
 *
 *  onCloseTab function is declared in parent component to handle tabs closing/removal
 */

export interface TabsContainerProps {
  tabs?: TabProps[];
  onCloseTab?: Function;
  selectLast?: boolean;
  tabFocus?: number;
  onChange?: Function | boolean; //override default tab Change callback
  tabsContainerId: string;
}

export interface TabProps {
  title: string | ReactNode;
  tabId: string;
  content: ReactNode;
  closeTab?: Function | boolean; //override default close tab callback
  permalink?: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      { value === index && (
        <Fragment>{children}</Fragment>
      )}
    </div>
  );
};

const a11yProps = (index:string | number) => {
  //a11y reads as accessibility
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
    key: `scrollable-auto-tab-key-${index}`,
  };
};

export const TabsContainer = ({ tabs, onCloseTab, selectLast, tabFocus, onChange, tabsContainerId}: TabsContainerProps) => {
  const [focus, setFocus] = useState(tabFocus || 0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    const permalink = event.target.getAttribute('data-permalink');
    if (event.target.tagName == 'DIV' || event.target.tagName == 'SPAN' || event.target.tagName === "BUTTON") { 
      if(typeof onChange == "function"){
        onChange({tabsContainerId, tabs, focus: newValue, permalink}, event);
      } else {
        setFocus(newValue);
      }
    }
  };  
  
  useEffect( () => {
   if(tabs && tabs.length > 0){
    if(selectLast) {
      setFocus(tabs.length - 1);
    } else {
      setFocus(tabFocus);
    }
   };   
  }, [tabs, selectLast, tabFocus]);

  // useEffect( () => {
  //   console.log("FOCUS", focus);
  //  }, [focus]);

  return (
    <S.Container component="div" id='appbar-container'>
      <S.AppBar position="static" color="default" id='appbar'>
        <S.Tabs
          id='tabs-row'
          value={focus}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
          TabIndicatorProps={{ children: <span /> }}
        >
          {
             tabs?.map( (tab, i) => 
               <S.Tab 
                  style={{
                    position: i == 0 ? "static" : 'relative'
                  }}
                  label={
                     <span data-permalink={tab.permalink}>
                        {tab.title}
                        {
                           (tab.closeTab && onCloseTab) && (
                              <S.IconButton 
                                onClick={ () => {                                   
                                  // setFocus(0); 
                                  const filteredTabs = tabs.filter((_tab, index) => (index !== i));

                                  //console.log("closed tab at ", i);

                                  if(typeof tab.closeTab === "function"){
                                    //console.log("tab custom close ");
                                    tab.closeTab(filteredTabs, i, focus); 
                                  } else {
                                    //console.log("tab component close ", onCloseTab.toString());
                                    onCloseTab(filteredTabs, i, focus);   
                                  }
                     
                                }} >
                                 <CancelPresentationIcon />
                              </S.IconButton>
                           )
                        }
                     </span>
                  }
                  data-permalink={tab.permalink}
                  {...a11yProps(i)} 
               />
             )
          }
        </S.Tabs>
      </S.AppBar>
      {tabs?.map((tab, i) => (
        <S.TabPanel value={focus} index={i} key={`tabpanel-${i}`}>
          {tab.content}
        </S.TabPanel>
      ))}
    </S.Container>
  );
};

const S = {
  Container: styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: "100%",
    padding: 0,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "hidden",
  })),
  IconButton: styled(IconButton)({
    margin: "5% 0px 5% 10px",
    color: "inherit",
    padding: "0px",
  }),
  Tabs: withStyles((theme: Theme) => ({
    indicator: {
      backgroundColor: "#FFFFFF",
    },
  }))(Tabs),

  Tab: withStyles((theme: Theme) => ({
    root: {
      backgroundColor: "#E0F1EF",
      "&$selected": {
        backgroundColor: "#FFFFFF",
        // border: `1px solid ${theme.palette.primary.light}`
        borderRadius: "5px",
      },
      
      borderTop: "1px solid #dcd9d9",
      "&:not(:first-child)": {
        borderLeft: "1px solid #dcd9d9",
      },
      "&:last-child": {
        borderRight: "1px solid #dcd9d9",
      }
    },
    selected: {}
  }))(Tab),

  TabPanel: styled(TabPanel)({
    // backgroundColor: "#E0F1EF",
    flexGrow: 1,
    position: "relative",
    // height: "100vh",
    overflow: "auto",
  }),

  AppBar: styled(AppBar)({
    boxShadow: "none",
  }),
};

export const tabExist = (tabs: TabProps[] | undefined, tabId: string) => {
  if(typeof tabs === 'undefined') return -1;
  return tabs.findIndex((tab)=>tab.tabId === tabId);
};