
import React from "react";
import { styled } from "@material-ui/core/styles";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FiberManualRecordTwoToneIcon from '@material-ui/icons/FiberManualRecordTwoTone';

export const StatusIcon = ({value}:any) => <div>{value ? <S.Active />: <S.Inactive />}</div>

const S = {
  Active: styled(FiberManualRecordIcon)({
    color: '#28E713',
  }),
  Inactive: styled(FiberManualRecordTwoToneIcon)({
    color: '#ff5252',
  }),
}