import React, { useEffect, useState } from "react";
import {
  isBrowser, logout, loginHref, isLoggedIn, getRefreshToken, refreshAuthentication
} from "../common/utils/auth";
import { RouteComponentProps } from "@reach/router";
import { ContentLoading } from "./loading";
import { useSiteMetadata } from "../common/hooks/useSiteMetaData";

interface Props {
  component: any;
  pagetitle?: string;
}

export const PrivateRoute = ({
  component: Component,
  location,
  pagetitle,
  ...rest
}: Props & RouteComponentProps) => {
  const { title } = useSiteMetadata();
  const [pageLoading, setPageLoading] = useState(true);
  
  useEffect(() => {
    if (isBrowser){
      document.title = pagetitle || title;
    }
    setPageLoading(false);
  }, []);

  return pageLoading ? <ContentLoading message="Loading..." /> :  (<Component {...rest} />);
};
