import React, { useEffect, useState, ReactNode, CSSProperties, useRef } from "react";
import { Avatar, Card, CardActionArea, CardContent, CardHeader, Modal, Theme, Typography, createStyles,makeStyles, Icon, IconButton, Tooltip  } from "@material-ui/core";
import styled from "styled-components";
import { styles } from "../styles";
import { Rnd } from 'react-rnd';
import CloseIcon from '@material-ui/icons/Close';
import TabIcon from '@material-ui/icons/Tab';

interface State {
  isOpen: boolean;
  content: ReactNode;
  button: string | false;
  title?: string;
  icon?: ReactNode;
  iconColor?: "violet" | "orange" | "rose" | "green";
  customHeaderButtons?: any;
}

interface ModalInterface {
  Modal: () => JSX.Element;
  closeModal: () => void;
  openModal: (params?: ModalContent) => void;
  setContent: (content: ReactNode) => void;
}

interface ModalContent {
  content: ReactNode;
  button?: string | false;
  title?: string;
  icon?: ReactNode;
  iconColor?: "violet" | "orange" | "rose" | "green";
  customHeaderButtons?: ReactNode;
  yPos?: number;
}


export const useModal = (style?: CSSProperties, vwf?:number): ModalInterface => {
  const width = (window.innerWidth * (vwf || 0.6)); //60% of the screen
  const xPosition = (window.innerWidth / 2) - (width / 2); //center position

  const [content, setContent] = useState<State["content"]>(<div />);
  const [button, setButton] = useState<State["button"]>(false);
  const [isOpen, setIsOpen] = useState<State["isOpen"]>(false);
  const [title, setTitle] = useState<State["title"]>();
  const [icon, setIcon] = useState<State["icon"]>();
  const [iconColor, setIconColor] = useState<State["iconColor"]>("orange");
  const [customHeaderButtons, setCustomHeaderButtons] = useState<State["customHeaderButtons"]>();
  const [yPos, setYPos] = useState<number | undefined>(50);

  const openModal = (params?: ModalContent) => {
    if (params?.content) setContent(params?.content);
    if (params?.button) setButton(params?.button);
    if (params?.title) setTitle(params?.title);
    if (params?.icon) setIcon(params?.icon);
    if (params?.iconColor) setIconColor(params?.iconColor);
    if (params?.customHeaderButtons) setCustomHeaderButtons(params?.customHeaderButtons);
    if (params?.yPos) setYPos(params?.yPos); else setYPos(undefined);
    setIsOpen(true);
  };
  const closeModal = () => setIsOpen(false);

  // var height = 0;
  // const inputRef = useRef(null);
  // useEffect(() => {
  //   setTimeout(() => {
  //     height = inputRef?.current?.offsetHeight;
  //     console.log('Input height', height);
  //   }, 1);

  // }, [content]);
  const classes = useStyles();

  const modal = () => (
    <Modal open={isOpen} onClose={closeModal} disableBackdropClick> 
      <S.Modal style={style}>
        <Rnd
          default={{
            x: xPosition,
            y: yPos || 50,
            height: 350,
            width: width
          }}
          minWidth={500}
          minHeight={350}
          bounds="window"
          disableDragging={false}
          dragHandleClassName="dragHandle"
          enableUserSelectHack={false}
          enableResizing={
            {
              top: false,
              right: true,
              bottom: false,
              left: true,
              topRight: false,
              bottomRight: false,
              bottomLeft: false,
              topLeft: false
            }
          }
        >
        { title ? (
          <Card className={classes.card} id="ModalScrollController">
            <CardActionArea className={`dragHandle`}>
              <CardHeader
                avatar={
                  icon ? (
                    <Avatar className={`${classes.avatar} ${iconColor}`}>
                      <Icon className={classes.cardHeaderIcon}>
                        {icon}
                      </Icon>
                    </Avatar>
                  ):
                  <div />
                }
                title={
                  <Typography className={classes.modalTitle} variant="h5" gutterBottom>
                    {title}
                  </Typography>
                }
                subheader=""
                className={classes.cardHeader}
                // action={
                //   <div>
                //     <IconButton aria-label="settings"
                //       onClick={() => {
                //         closeModal();
                //       }}
                //     >
                //       <div>
                //         <CloseIcon />
                //       </div>
                //     </IconButton>
                //   </div>
                // }
              />
            </CardActionArea>
            
            { customHeaderButtons || 
              <CardContent className={classes.cardHeaderActionsContainer}>
                <Tooltip title="Close">
                  <IconButton aria-label="settings"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </CardContent> 
            }
            <CardContent className={classes.cardFooter}>
              {content}
            </CardContent>
          </Card>
        ) : 
          content
        }
          {button && (
            <S.Buttons>
              <S.CancelButton
                onClick={() => {
                  closeModal();
                }}
              >
                {button}
              </S.CancelButton>
            </S.Buttons>
          )}
        </Rnd>
      </S.Modal>
    </Modal >
  );

  return { Modal: modal, closeModal, openModal, setContent };
};

const S = {
  Modal: styled.div`
    padding: 0px;
  `,
  CancelButton: styled.button`
    ${styles.button.base};
    ${styles.button.error};
    margin-left: auto;
    margin-right: auto;
  `,
  Buttons: styled.div`
    margin-top: 40px;
    ${styles.flex.row};
    justify-content: space-between;
  `,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    card: {
      overflowY: "auto",
      maxHeight: "90vh"
    },
    cardHeader: {
      textAlign: "left",
    },
    avatar: {
      borderRadius: "3px",
      backgroundColor: "#999999",
      padding: "15px",
      marginTop: "-25px",
      marginRight: "15px",
      float: "left",
      width: "auto",
      height: "auto",

      "&&.violet": theme.headerAvatarColor.violet,
      "&&.rose": theme.headerAvatarColor.rose,
      "&&.green": theme.headerAvatarColor.green,
      "&&.orange": theme.headerAvatarColor.orange,

      "&& svg": {
        fontSize: "36px",
        lineHeight: "56px",
        width: "56px",
        height: "56px",
        textAlign: "center",
      }
    },
    cardHeaderIcon: {
      fontSize: "36px",
      lineHeight: "56px",
      width: "56px",
      height: "56px",
      textAlign: "center",
    },
    cardHeaderActionsContainer: {
      position: "absolute", 
      top: "0px", 
      right:"0px"
    },
    cardFooter: {
      padding: "0.5rem 0",
      borderTop: "1px solid #eeeeee",
      paddingBottom: "0.75rem",
      margin: "0px 0px 0px",
      fontSize: "12px",
      "&&:last-child": {
        paddingBottom: "0.5rem",
      },
      overflowY: "visible"
    },
    modalTitle: {
      textTransform: "capitalize",
    }
  })
);