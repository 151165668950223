import React from "react";
import styled from "styled-components";
import { styles } from "../styles";
import {
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Select as MUISelect,
  Switch as MUISwitch,
  Checkbox as MUICheckbox,
  Radio as MUIRadio,
  CheckboxProps as MUICheckboxProps,
  RadioProps as MUIRadioProps,
} from "@material-ui/core";
import { usePassword, handleErrorMessage } from "../common/hooks";
import { Icon } from "@iconify/react";
import eyeIcon from "@iconify/icons-mdi/eye";
import eyeOff from "@iconify/icons-mdi/eye-off";
import { Controller, FormContextValues } from "react-hook-form";
import { formTools, convertColor } from "../common/utils";
import { withStyles } from "@material-ui/styles";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  > * {
    // margin: 10px 0px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  // > button {
  //   ${styles.button.base};
  //   ${styles.button.secondary};
  // }
`;

export const usePasswordComponent = () => {
  const { show, toggle } = usePassword();
  return {
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            edge="end"
            aria-label="toggle password visibility"
            onClick={toggle}
            onMouseDown={e => {
              e.preventDefault();
            }}
          >
            {show ? <Icon icon={eyeOff} /> : <Icon icon={eyeIcon} />}
          </IconButton>
        </InputAdornment>
      ),
    },
    type: show ? "text" : "password",
  };
};

interface FormProps {
  name: string;
  form: FormContextValues<Record<string, any>>;
  InputProps?: any;
  ControllerProps?: any;
}

interface InputProps extends FormProps {
  label?: string;
  hidden?: boolean;
  defaultValue?: string;
}

const Input = ({
  name,
  label,
  form: { errors, control },
  hidden,
  InputProps,
  ControllerProps,
  defaultValue,
}: InputProps) => {
  return (
    <Controller
      as={
        <TextField
          variant="outlined"
          label={label}
          {...(hidden && { style: { display: "none" } })}
          {...handleErrorMessage(name, errors)}
          {...InputProps}
        />
      }
      name={name}
      control={control}
      defaultValue={defaultValue}
      {...ControllerProps}
    />
  );
};

const ColorRadio = withStyles({
  root: {
    "&$checked": {
      color: styles.color.primary,
    },
  },
  checked: {},
})((props: MUIRadioProps) => <MUIRadio color="default" {...props} />);

interface RadioProps extends FormProps {
  options: { label: string; value: string }[];
  defaultValue?: string;
}

export const Radio = ({
  name,
  form: { control },
  options,
  defaultValue,
  InputProps,
  ControllerProps,
}: RadioProps) => {
  return (
    <Controller
      as={
        <RadioGroup>
          {options.map(({ label, value }) => (
            <FormControlLabel
              key={value}
              value={value}
              control={<ColorRadio />}
              label={label}
              defaultChecked={!!defaultValue && value === defaultValue}
              {...InputProps}
            />
          ))}
        </RadioGroup>
      }
      name={name}
      control={control}
      defaultValue={defaultValue}
      {...ControllerProps}
    />
  );
};

interface SelectProps extends FormProps {
  label?: string;
  options?: { label: string; value: string }[];
  groupOptions?: {
    label: string;
    values: { label: string; value: string }[];
  }[];
  formHelpers: {
    Select: { inputLabel: any; labelWidth: any; addToInputLabel: any };
  };
  defaultValue?: string;
}

const Select = ({
  name,
  label,
  options,
  groupOptions,
  form: { errors, control },
  formHelpers: {
    Select: { inputLabel, labelWidth, addToInputLabel },
  },
  defaultValue,
  InputProps,
  ControllerProps,
}: SelectProps) => {
  if (label && !(name in inputLabel)) {
    addToInputLabel(name);
  }
  return (
    <FormControl variant="outlined" error={!!errors?.[name]}>
      {label && (
        <InputLabel id={`${name}-label`} htmlFor={name} ref={inputLabel[name]}>
          {label}
        </InputLabel>
      )}
      <Controller
        as={
          <MUISelect
            native
            id={`${name}-label`}
            labelWidth={labelWidth[name] ?? 0}
            inputProps={{ id: name }}
            defaultValue={defaultValue}
            {...InputProps}
          >
            <option value={""}></option>
            {groupOptions?.map(g => (
              <optgroup label={g.label}>
                {g.values.map(({ label, value }) => (
                  <option value={value}>{label}</option>
                ))}
              </optgroup>
            )) ??
              options?.map(({ label, value }) => (
                <option value={value}>{label}</option>
              ))}
          </MUISelect>
        }
        name={name}
        control={control}
        valueName="selected"
        defaultValue={defaultValue}
        {...ControllerProps}
      />
      {!!errors?.[name] && (
        <FormHelperText>
          {formTools.GetErrorMessage(name, errors)}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const ColorCheckbox = withStyles({
  root: {
    "&$checked": {
      color: styles.color.primary,
    },
  },
  checked: {},
})((props: MUICheckboxProps) => <MUICheckbox color="default" {...props} />);

interface CheckboxProps extends FormProps {
  options: { label: string; value: string }[];
  defaults?: string[];
}

export const Checkbox = ({
  name,
  options,
  form: { register },
  defaults,
  InputProps,
  ControllerProps,
}: CheckboxProps) => {
  // console.log(options);
  return (
    <FormGroup {...ControllerProps}>
      {options.map(({ label, value }, i) => (
        <FormControlLabel
          key={value}
          control={
            <ColorCheckbox
              name={name}
              value={value}
              inputRef={register}
              defaultChecked={defaults?.includes(value)}
              {...InputProps}
            />
          }
          label={label}
        />
      ))}
    </FormGroup>
  );
};

const ColorSwitch = withStyles({
  switchBase: {
    color: styles.color.grey_d1,
    "&$checked": {
      color: styles.color.primary,
    },
    "&$checked + $track": {
      backgroundColor: styles.color.primary,
    },
  },
  checked: {},
  track: {},
})(MUISwitch);

interface SwitchProps extends FormProps {
  label?: string;
  defaultChecked?: boolean;
}

export const Switch = ({
  name,
  form: { register },
  label,
  defaultChecked,
  InputProps,
  ControllerProps,
}: SwitchProps) => {
  return (
    <FormControlLabel
      control={
        <ColorSwitch
          name={name}
          inputRef={register}
          defaultChecked={defaultChecked}
          {...InputProps}
        />
      }
      label={label}
      {...ControllerProps}
    />
  );
};

export const FormComp = {
  Select,
  Checkbox,
  Input,
  Radio,
  Switch,
};
