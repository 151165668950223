import React, { ReactNode } from "react";
import { Paper } from "@material-ui/core";

interface Props {
   children?: ReactNode;
}
 
export const ContentContainer = ({ children }: Props) => {
   return (
      <Paper style={Styles.Content} className="ContentPaper">
         {children}
      </Paper>
   )
};

const Styles:any = {
   Content: {
      width: "100%",
      position: "relative",
      minHeight: "300px",
      flexGrow: 1,
      display: "flex",
   }
}
