import React from "react";
import JSONPretty from 'react-json-pretty';
import XMLViewer from 'react-xml-viewer'

const JSONPrettyMonikai = require('react-json-pretty/dist/monikai');
const JSONPrettyStyle = {
    width: '95%',
    margin: 'auto',
};
const XMLPrettyTheme = {
    // "attributeKeyColor": "#f92672",
    // "attributeValueColor": "#fd971f",
    // "tagColor": "#f92672",
    // "textColor": "#fd971f",
    // "separatorColor": "#66d9ef",
}
const XMLPrettyStyle = {
    width: '95%',
    margin: 'auto',
    marginTop: '20px',
    overflow: 'auto',
    // background: '#272822'
};

export const PrettyPayload = ({ value, style }: any) => {
    const parser = new window.DOMParser();
    const theDom = parser.parseFromString(value, 'application/xml');
    if (theDom.getElementsByTagName('parsererror').length > 0) {
        return <JSONPretty data={value} onJSONPrettyError={e => {}} theme={JSONPrettyMonikai} space={4} style={style ?? JSONPrettyStyle} />
    } else {
        return <XMLViewer xml={value} theme={XMLPrettyTheme} style={style ?? XMLPrettyStyle} />
    }
}